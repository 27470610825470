.lab-results-table > tbody {
  font-family: "Ubuntu Light";
}

.lab-table-code-select {
  cursor: pointer;
  color: #009fda;
}

// Note: The checkbox fits into the box, but due to a slight mismatch in sizing of the
// parent elements, it adds a single "." due to the text-overflow:: ellipses value
// This prevents that single "." from appearing but doesn't affect the display of the element
.lab-results-table-checkbox-col {
  text-overflow: clip !important;
}