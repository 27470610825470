@import '../styles/variables';

.app-container {
  background-color: $hv-bg-grey;
  overflow: auto;
  height: 100vh;
}

.contents {
  display: inline;
}

.page-loading {
  position: absolute;
  background-color: rgba($color: gray, $alpha: .4);
  width: 100%;
  height: 100%;
  background-image: url('/images/load-graphic-small.gif');
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 600;
}

.component-loading {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/images/load-graphic-small.gif');
  background-size: 50px 50px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
}

.ui.button:not(.primary) {
  color: #1b1b1b !important;
}

.Toastify__toast-body > div:not(.Toastify__toast-icon) {
  overflow-wrap: anywhere;
}

.unauthorized-message {
  box-sizing: border-box;
  margin: 75px auto;
  width: 1000px;
  background: #fff;
  border-radius: 3px;
  border: 2px solid #d6d6d6;
  color: #1b1b1b;

  h1 {
    padding: 20px;
    font-family: 'Ubuntu';
    margin: 0px 0px 0px 0px;
    font-size: 26px;
  }

  .message-content {
    padding: 20px;
  }

  p {
    font-family: 'Ubuntu Light';
    font-size: 16px;
  }

  a {
    text-decoration: underline;
  }

  .float-right {
    float:right;
  }
}

.content-divider {
  width: 100%;
  margin: 0;
  border-top: 1px solid #D6D6D6;
}
