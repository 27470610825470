.table-container {
  overflow: auto;
  min-height: calc(75vh - 150px);
  max-height: calc(75vh - 150px);
  width: 100%;
}

.table-ref {
  overflow-y: auto;
  margin: 10px 0 20px 20px;
  padding-right: 20px;
}

table {
  &.query-table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    font-size: 13px;
  }
  
  &.hidden {
    display: none;
  }
}

.table-header {
  font-family: 'Ubuntu';

  text-align: left;
  padding: 0.75em 0.75em 0.75em 0;
  vertical-align: bottom;
  white-space: nowrap;

  background-color: #3f86a8;
  color: #ffffff;

  position: sticky;
  top: 0;
  z-index: 12;

  &:first-child {
    padding-left: 2em;
  }
}

.table-row {
  font-family: 'Ubuntu Light';
  color: #1b1b1b;
  border-top: 1px solid #d6d6d6;

  &:hover {
    td {
      background-color: #f4f4f4;
    }
  }

  td {
    padding: 0.5em 0.75em 0.5em 0;
    max-width: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: inherit;

    &:first-child {
      padding-left: 2em;
    }
  }

  td.details-link {
    &:hover {
      cursor: pointer;
    }
    &.incomplete-report {
      &:hover {
        cursor: unset;
      }
    }
  }
  td[colspan="7"] {
    text-align: center;
  }
}

.no-results {
  display: flex;
  font-family: 'Ubuntu Light';
  font-style: italic;
  color: #909090;
  margin: 50px 0px 100px;
  justify-content: center;
  align-items: center;
}

.no-search-results {
  margin: 40px;
  color: #1b1b1b;

  h2 {
    font-family: 'Ubuntu';
    margin: 0px 0px 5px 0px;
  }

  p {
    font-family: 'Ubuntu Light';
    font-size: 16px;
  }

  li {
    font-family: 'Ubuntu Light';
  }

  span {
    font-family: 'Ubuntu Medium';
  }
}

.table-first-row {
  display: flex;
  align-items: center;
}

.table-first-row-checkbox {
  padding-right: 5px;
}

.action-menu-cell {
  overflow: visible !important;
  .action-kebab-menu {
    width: 25px;
    height: 25px;
    border-radius: 30px;
    &:hover {
      background: #dbdbdb;
    }
    svg {
      vertical-align: middle;
    }
  }
}

.refresh-button-icon {
  display: inline-block;
  position: relative;
  top: 4px;
  left: 3px;

  svg {
    cursor: pointer;
    transition: transform .2s ease-in-out;
  }
  svg:hover {
    transform: rotate(60deg);
  }
} 
