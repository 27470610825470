@import '../../styles/variables';

button {
    cursor: pointer;
}

.query-detail-toolbar {
    margin-top: 45px;
    height: 30px;
    background-color: #8F8F8F;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div.container {
        display: flex;
        align-content: center;
        padding: 4px 10px;

        div.query-title {
            color: white;
            text-align: center;
            flex: 2 2 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        div.options-menu {
            flex: 1 1 0px;

            div.action-kebab-menu { 
                float: right;
                padding-top: 1px;
            }
        }

        div.home-icon {
            flex: 1 1 0px;
        }

        div.element {
            height: 100%;
        }
    }
}

.options-button {
    display: flex;
    font-family: Ubuntu-Medium;
    font-size: 12px;
    color: #FFFFFF;
    background-color: #8F8F8F;
    height: 22px;
    width: 83px;
    border: 1px solid #FFFFFF;
    border-radius: 11px;
    justify-content: center;
    align-items: center;
}

.dark-gray-background {
    background-color: #E3E3E3;
}

.query-details-container {
    z-index: 1030;
    width: 960px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .query-notes-container {
        margin-top: 10px;
        min-height: 75px;
        width: 960px;
        background-color: #FFFFFF;
        padding: 20px;
        overflow: auto;
    }
}

.query-attributes-container {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &.query-results-container {
        margin-left: 20px;
        margin-top: 0px;
        width: calc(100% - 20px);
    }

    .query-details-table-container {
        width: 100%;
        background-color: #FFFFFF;
        border-radius: 3px;
        &:not(:first-child) {
            margin-top: 20px;
        }
    }

    #provider-counts-table th {
        background-color: $hv-bg-blue;
    }

    table.query-details-table {
        th.table-header {
            &:first-child {
                border-radius: 3px 0px 0px 0px;
            }
            &:last-child {
                border-radius: 0px 3px 0px 0px;
            }
        }
        th.table-header, td {
            &:first-child {
                border-right: 1px solid #D6D6D6;
            }
            padding-left: 10px;
        }
        thead > tr > th:first-child {
            width: 25%;
        }
        tbody > tr:hover > td {
            background-color: rgba(0, 0, 0, 0);
        }
    }
    table.query-results-table {
        border-color: #D6D6D6;
        border-style: solid;
        border-width: 1px;
    }
}

.query-notes-text {
    font-family: 'Ubuntu-Light';
    color: #1b1b1b;
    font-size: 13px;
}

.results-block-title {
    display: flex;
    flex-direction: row;
}

.results-block-group {
    font-weight: 400;
}

.results-block-detail {
    margin-left: 20px;
}

.results-emphasis {
    font-weight: bold;
}
