@import '../../../styles/variables';

$criteria-modal-message-height: 73px;

// 70px equals 35px plus the footer's margin heights, see .generic-modal-footer
$criteria-modal-footer-height: 70px;

.fixed-height-grid {
  height: 100% !important;
}

.scrolling-pane {
  height: 100%;
  overflow-y: auto;
}

.select-values-textarea {
    border: 1px solid #D6D6D6;
    border-radius: 3px;
    resize: none;
    padding: 10px;
}

.selected-values-container {
    display: flex;
    justify-content: left;
    align-items: center;
    max-height: 400px;
}

.value-set-modal {
    width: 900px;
    .generic-modal-body {
        padding: 0px;
        height: calc(100% - $criteria-modal-footer-height);
    }
    .generic-modal-footer {
        height: 35px;
    }
    height: 100%;
}

.value-set-modal-grid {
    padding: 5px 20px 20px 20px;
    max-width: 900px;
    min-width: 900px;
    height: calc(100% - $criteria-modal-message-height);
}

.value-set-modal-header-message {
    height: $criteria-modal-message-height;
    
    #criteria-config-message {
        box-shadow: unset;
        background-color: #E8F5DE;
        color: black;
        border-radius: 3px;
        p {
            font: $ubuntu-light;
        }
    }
}

.selected-value-sets-container {
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 3px;
    padding: 5px;
    max-height: 200px;
    overflow-y: auto;
    .ui.table {
        border:none;
    }
}

.ui.grid.selected-values-and-value-sets-container {
    padding-left: 12px;
}

.value-set-modal-grid > .column > div {
    .ui.button.value-set-modal-button {
        min-width: 150px;
        box-shadow: none;
        background: none;
        font-size: smaller;
        &:hover {
            background-color: #F6F6F6;
        }
    }
    .clear-text-button {
        float: right;
    }
    &.value-and-value-set-header-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    
        h5 {
            min-width: 220px;
            margin: 0;
            line-height: 30px;
        }
    }
    &:not(:nth-last-of-type(1)) {
        margin-bottom: 10px;
    }
}
