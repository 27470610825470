.sort-arrows-container {
  float: left;
  margin: 0px 5px 0px 0px;
  fill: black;
  svg path {
    opacity: 0.5;
  }
  .sort-up-arrow-highlight .a {
    opacity: 1;
  }
  .sort-down-arrow-highlight .b {
    opacity: 1;
  }
}
