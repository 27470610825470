.value-set-creation-modal {
  max-width: 200px;
  box-sizing: border-box;

  .value-set-creation-modal-body {
    input.gray-text-input {
      margin-bottom: 10px;
    }
  }
}
