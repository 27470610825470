@import '../../styles/variables';


.value-set-selection-row {
    &:not(:nth-last-of-type(1)) > .ui.segment.selected-value-set {
        margin-bottom: 5px;
    }
    .ui.segment.selected-value-set {
        background-color: $hv-bg-grey;

        .selected-value-set-name {
            font-weight: bold;
        }
        .selected-value-set-description {
            font-style: italic;
        }
        .ui.button.unpack-button {
            margin-top: 5px;
        }
        .ui.button.selected-value-set-button {
            box-shadow: none;
        }
        .ui.button.discard-button {
            justify-content: center;
            min-width: 30px;
            min-height: 30px;
            border: none;
            background: $hv-bg-grey;
        }
    }
}
