/* CENTER MODAL VARIABLES */
$center-modal-max-height: 80vh;

/* GENERIC MODAL VARIABLES */
$generic-modal-header-height: 56px;
$generic-modal-footer-height: 35px;  // 45px equals 35px plus the footer's margin-bottom
$generic-modal-footer-margin-bottom: 10px;  // 45px equals 35px plus the footer's margin-bottom

/* STYLES */
.center-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 200;
  height: 100%;
  width: 100%;
  background-color: black;
  transition: opacity 0.3s;
}

.center-modal {
  opacity: 1;
  background-color: white;
  z-index: 200;
  border-radius: 3px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.301);
  max-height: $center-modal-max-height;

  // The following attributes position
  // this modal directly in the middle of the screen
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50vh;  // Based on view-height
  left: 50vw;  // Based on view-width

  &.center-modal_fixed-height {
    .generic-modal {
      height: 100%;
      .generic-modal-body {
        height: calc(
          100% -
          $generic-modal-header-height -
          $generic-modal-footer-height -
          $generic-modal-footer-margin-bottom
        );
        overflow-y: auto;
      }
    }
  }
}

.generic-modal {
  min-width: 520px;

  // Generic modal header
  .generic-modal-header {
    border-bottom: 1px #d6d6d6 solid;
    color: #1b1b1b;
    height: $generic-modal-header-height;

    h1 {
      font-size: 20px;
      margin: 0;
      padding: 15px 20px;
    }
  }

  // Generic modal Body
  .generic-modal-body {
    padding: 15px 20px;
  }

  // Generic modal footer
  .generic-modal-footer {
    text-align: right;
    padding-right: 20px;
    height: $generic-modal-footer-height;
    margin-bottom: $generic-modal-footer-margin-bottom;
    padding-top: 10px;
    border-top: 1px solid #d6d6d6;

    // Extra modal button within footer
    .generic-modal-extra-button-bottom-left {
      float: left;
      margin-left: 20px;
      cursor: pointer;
    } 
   
    // Dismiss button within footer
    .generic-modal-dismiss-btn {
      width: fit-content;
      min-width: 100px;
      height: 25px;
      background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat
        padding-box;
      box-shadow: 0px 0px 6px #0000001a;
      border: 1px solid #d6d6d6;
      border-radius: 3px;
      font-family: 'Ubuntu Medium';
      font-size: 13px;
      color: #1b1b1b;
      cursor: pointer;

      &:hover {
        background: #f4f4f4 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #0000001a;
        border: 1px solid #d6d6d6;
        border-radius: 3px;
      }
    }

    // Confirm button within footer
    .generic-modal-confirm-btn {
      width: fit-content;
      min-width: 100px;
      padding: 0 20px 0 20px;
      height: 25px;
      background: transparent linear-gradient(180deg, #72b456 0%, #578c41 100%) 0% 0% no-repeat
        padding-box;
      box-shadow: 0px 0px 6px #00000033;
      border-radius: 3px;
      border: 0px transparent;
      font-family: 'Ubuntu Medium';
      font-size: 13px;
      color: #ffffff;
      margin-left: 10px;
      cursor: pointer;

      &:not([disabled]):hover {
        background: transparent linear-gradient(180deg, #578c41 0%, #578c41 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 0px 6px #00000033;
        border-radius: 3px;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
