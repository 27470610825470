.toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .button {
        display: flex;
        height: 24px;
        width: 95px;
        border: solid #144A65;
        opacity: 1;
        align-items: center;
        justify-content: center;
        font: 13px Ubuntu Medium;
        color: #144A65;
    }

    .left {
        border-width: 2px 1px 2px 2px;
        border-radius: 15px 0px 0px 15px;
    }

    .right {
        border-width: 2px 2px 2px 1px;
        border-radius: 0px 12px 12px 0px;
    }

    .selected {
        background: #CDEBF8 0% 0% no-repeat padding-box;
    }

    .unselected {
        background: #FFFFFF 0% 0% no-repeat padding-box;
    }
}
