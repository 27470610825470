// Container for the search bar itself
.reference-code-search-bar {
  // Style the input search bar similar to the text box
  input {
    background-color: #f4f4f4 !important;
    font-style: italic;
  }

  input::-webkit-input-placeholder {
    color: rgb(117, 117, 117) !important;
  }
}

// Container for the results section
.reference-code-results-section {
  padding: 10px 0px;
}
