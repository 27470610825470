@import '../../../styles/variables';

.enrollment-dropdown {
  width: 520px;
}

.duration-input {
  width: 105px;
  padding-top: 10px;
}

.enrollment-btn {
  display: flex;
  height: 26px;
  cursor: pointer;
  margin: 5px;

  .enrollment-icon {
    background: linear-gradient(180deg, $hv-button-light-green 0%, $hv-button-dark-green 100%);
    width: 26px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    box-sizing: border-box;
    border-radius: 3px;

    img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &:hover {
    .new-query-text {
      background: $semantic-light-grey;
    }
    .new-query-icon {
      background: $hv-button-dark-green;
    }
  }
}

.enrollment-criteria-display {
  margin: 1px 10px;
  padding: 5px 10px;
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  background-color: #D6D6D6;
}

.enrollment-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.enrollment-criteria-name {
  padding-right: 20px;
}

.enrollment-criteria-display-values {
  display: flex;
  align-items: center;
}

.enrollment-container {
  border-top: #a0a0a0 1px solid;
  margin-top: 5px;
  padding-top: 5px;
}

.enrollment-criteria-item {
  padding-right: 20px;
  display: flex;
  flex-direction: row;
}

.enrollment-criteria-line {
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .line-element {
    margin-left: 10px;
  }

  .display {
    margin: 1px 10px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #D6D6D6;
  }
}

.light-background {
  background-color: white;
  padding: 2px;
  border: #a0a0a0 solid;
  border-radius: 3px;
}

.enrollment-separator-string {
  padding: 2px;
  margin: 5px;
  background-color: white;
  color: #C9C9C9;
  font-size: 13px;
}
