$hv-bg-grey: #f1f1f1;
$hv-bg-green: #72B456;
$hv-bg-green-dark: #5E9645;
$hv-bg-blue: #3F86A8;
$hv-bg-blue-dark: #316E8B;

$hv-button-light-green: #72b456;
$hv-button-dark-green: #578c41;
$semantic-light-grey: #e3e3e3;

$ubuntu-light: normal normal normal 13px/24px Ubuntu Light;
