.query-blder-details-header {
    h1 {
        margin-left: 0px;
        font-size: 20px;
    }
}

.query-blder-details-body {
    h5 {
        font-size: 14px;
        margin-bottom: 3px;
    }
}

.query-blder-details-footer {
    border-top: 1px #d6d6d6 solid;
    padding-bottom: 2px;
}

.query-blder-details-modal-textarea-element {
    border: 1px #d6d6d6 solid;
    border-radius: 4px;
    padding: 8px;
    min-width: 700px;
    max-width: 700px;
    min-height: 80px;
    background-color: #F4F4F4;
    resize: none;

    &::placeholder {
        font-style: italic;
        color: #C4C4C4;
    }
    &:focus {
        outline: 0;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #00000040;
        border: 1px solid #A0A0A0;
    }
}

.query-blder-details-modal-input-element.ui.input {
    background-color: #F4F4F4;
    max-width: 350px;
}

.query-blder-details-modal-input-element.ui.input > input {
    background: transparent;
}

.query-blder-details-modal-input-element.element-2.correct.ui.input > input:focus { 
    outline: 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000040;
    border: 1px solid #A0A0A0;
}

.query-blder-details-modal-input-element.element-2.error.ui.input > input:focus { 
    background-color: #FFF;
}

.query-blder-details-modal-input-element.element-1.ui.input > input:focus {
    outline: 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000040;
    border: 1px solid #A0A0A0;
}

.query-blder-details-modal-input-element.ui.input > input::placeholder {
    font-style: italic;
    color: #C4C4C4;
}



.query-blder-details-field-ids {
    display: flex;
    flex-direction: row;
    align-items: center; 
}

.query-blder-details-field-prefix {
    padding: 5.5px;
    border: 1px #d6d6d6 solid;
    border-radius : 4px 0px 0px 4px;
    border-right-width: 0px;
}

.query-blder-details-modal-input-element.element-2.ui.input > input {
    border-radius: 0px 4px 4px 0px;
    min-width: 313px;
}
