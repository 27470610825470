.nav-container {
  width: 100%;
  height: 45px;
  position: fixed;
  z-index: 500;

  box-shadow: 0px 0px 6px #0000001a;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.nav-section {
  flex-grow: 1;
  padding: 10px;
  flex-basis: 0;
}

.nav-center {
  text-align: center;
}

.nav-center > img {
  vertical-align: middle;
}

.nav-right {
  text-align: right;
  height: 100%;
}

.logout-button {
  float: right;
}
