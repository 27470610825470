.index-event-configuration {
    width: 100%;
    border: 1px solid #D6D6D6;
    border-radius: 3px;
    padding: 10px;
    background: white;

    #time-selection {
        display: flex;
        #record-timing-selection {
            display: flex;
            div {
                cursor: pointer;
                user-select: none;
                box-sizing: border-box;
                color: #D6D6D6;
                height: 30px;
                line-height: 30px;
                text-align: center;
                width: 110px;
                margin: auto;
                &:first-of-type {
                    border: 1px solid #D6D6D6;
                    border-radius: 3px 0px 0px 3px;
                    margin-right: -1px;
                }
                &:last-of-type {
                    border: 1px solid #D6D6D6;
                    border-radius: 0px 3px 3px 0px;
                }
                &.selected {
                    z-index: 1;
                    color: #144A65;
                    background: #CCE2ED;
                    border-color: #3F86A8;
                }
            }
        }
    }
}

.index-event-icon-container {
    margin-top: 5px;
    margin-right: 10px;
    &:empty {
        // Only keep margin if an icon
        // element is present in this container
        margin-top: 0px;
        margin-right: 0px;
    }
    .index-event-unselected {
        &:hover {
            #Ellipse_6812 {
                fill: #939393;
            }
            #Intersection_110 {
                fill: #939393;
            }
        }
    }
    .index-event-selected {
        &:hover {
            #Ellipse_6812 {
                fill: #939393;
            }
            #Intersection_110 {
                fill: #939393;
            }
        }
    }
}

.index-event-row {
    display: flex;
    flex-direction: row;
}

.enrollment-criteria-registered {
    margin: 0;
    display: flex;
    align-items: center;
    border: #939393 1px solid;
    border-radius: 3px;
    background-color: #D6D6D6;

    div.criteria-options-select {
        min-width: 205px;
        padding-right: 5px;

        div.select__menu {
            z-index: 11;
        }
    }
}
