@import '../../styles/variables';

.report-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 75px auto;
  width: 1000px;
  background: white;
  border-radius: 3px;
  border: 2px solid #D6D6D6;

  .report-list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    padding-bottom: 20px;

    .report-list-title {
      font-family: Ubuntu, Regular;
      font-size: 26px;
    }
    .report-list-buttons {
      display: flex;
    }
  }

  .report-list-divider {
    width: 100%;
    margin: 0;
    border-top: 1px solid #D6D6D6;
  }
  
  .report-list-content {
    width: 100%;
    padding: 20px;
  }
}

.new-report-btn {
  display: flex;
  height: 26px;
  cursor: pointer;

  .new-report-text {
    background: linear-gradient(180deg, white 0%, $semantic-light-grey 100%);;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border: 1px solid #d6d6d6;
    border-left: none;
    border-radius: 0 3px 3px 0;
    color: #1b1b1b;
  }

  .new-report-icon {
    background: linear-gradient(180deg, $hv-button-light-green 0%, $hv-button-dark-green 100%);;
    width: 26px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    box-sizing: border-box;
  
    background: linear-gradient(180deg, $hv-button-light-green 0%, $hv-button-dark-green 100%);;
    border-radius: 3px 0 0 3px;
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  &:hover {
    .new-report-text {
      background: $semantic-light-grey;
    }

    .new-report-icon {
      background: $hv-button-dark-green;
    }
  }
}
