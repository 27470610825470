.supplier-dropdown {
    width: 700px;
    border: 1px #d6d6d6 solid;
    border-radius: 4px;
    background-color: #F4F4F4;
    font-style: italic;
}

.date-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 350px;
    margin-top: 20px;
}

.date-input {
    width: 170px;
    height: 35px;
    border: 1px #d6d6d6 solid;
    border-radius: 4px;
    background-color: #F4F4F4;
    padding-left: 15px;
    font-style: italic;
}
