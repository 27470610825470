@import '../../styles/variables';

button {
    cursor: pointer;
}

.query-blder-toolbar {
    position: fixed;
    z-index: 1030;
    margin-top: 40px;
    height: 30px;
    width: 100%;
    background-color: #8F8F8F;
    display: flex;
    align-items: center;
}

.query-blder-toolbar-details-button {
    margin: 0px 10px;
    display: inline-block;
}

.query-bldr-container {
    margin: 80px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.query-bldr-bordered-container {
    display: flex;
    position: relative;
    border-radius: 10px 3px 3px 10px;
    border: 3px solid #D6D6D6;
    background-color: #FFF;
}

.query-blder-hover-container {
    &:hover {
        border-color: CriteriaGroupProps;
    }
}

.query-bldr-block {
    @extend .query-bldr-bordered-container;
    width: 1000px;
    margin: 15px 0;
}

.query-bldr-block-include {
    @extend .query-bldr-block;
    border-color: $hv-bg-green;
}

.query-bldr-block-exclude {
    @extend .query-bldr-block;
    border-color: #BE6C4D;
}

.query-bldr-block-type-container {
    display: inline-block;
    position: absolute;
    right: 20px;
    top: -12px;
    border: 2px solid $hv-bg-green;
    border-radius: 10px;
    background-color: #E5F8DD;
    z-index: 1;
    font: normal normal normal 13px/14px Ubuntu Medium;
}

.query-bldr-block-type-container-include {
    @extend .query-bldr-block-type-container;
    background-color: #E5F8DD;
}

.query-bldr-block-type-container-exclude {
    @extend .query-bldr-block-type-container;
    border-color: #BE6C4D;
    background-color: #FFE5DB;
}

.query-bldr-block-type {
    padding: 3px 10px;
}

.query-bldr-block-sidebar {
    width: 20px;
    background-color: #D6D6D6;
    border-radius: 7px 0px 0px 7px;
}

.query-bldr-group-sidebar {
    width: 10px;
    background-color: #D6D6D6;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.query-bldr-group-label {
    font: normal normal normal 20px Ubuntu Medium;
    border: 2px solid;
    border-radius: 10px;
    background-color: #E5F8DD;

}

.query-bldr-block-join-type-container {
    @extend .query-bldr-block-sidebar;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.query-bldr-block-join-type-container-and {
    @extend .query-bldr-block-join-type-container;
    background-color: $hv-bg-green;

    &:hover {
       background-color: $hv-bg-green-dark;
    }
}

.query-bldr-block-join-type-container-or {
    @extend .query-bldr-block-join-type-container;
    background-color: $hv-bg-blue;

    &:hover {
        background-color: $hv-bg-blue-dark;
    }

}
.query-bldr-block-join-type {
    font: normal normal normal 13px/14px Ubuntu Medium;
    color: #FFF;
    transform: rotate(-90deg);
}

.query-blder-feed-groups {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.query-blder-feed-group-container {
    @extend .query-bldr-bordered-container;
    border-width: 2px;
    margin: 20px 10px 10px 10px;
}

.query-blder-feed-group-body {
    display: flex;
    align-items: center;
    width: 100%;
}

.query-blder-feed-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.query-blder-close-group {
    padding: 5px;
    margin: 0 10px 0 0;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
        color: white;
        font-weight: bold;
        background-color: #B5B5B5;
    }
}

.query-blder-feed-group-feeds {
    display: flex;
    position: absolute;
    left: 20px;
    top: -12px;
    z-index: 1;
    background-color: #FFF;
    font-family: Ubuntu Medium;
}

.query-blder-add-feed {
    padding: 0px 7px;
    font-style: italic;
}

.query-blder-add-criteria-group {
    width: 40px;
    height: 20px;
    margin: 5px;
    line-height: 2px;
    align-self: center;
}

.query-blder-feed-container {
    display: flex;
    align-items: center;
    margin: 0 5px;
    border: 2px solid #D6D6D6;

    &:first-of-type {
        margin-left: 10px;
    }
}

.query-blder-feed-color {
    width: 5px;
    height: 100%;
    background-color: gray;
}

.datatype-color-consumer {
    background-color: rgb(44, 155, 154);
}

.datatype-color-emr {
    background-color: rgb(216, 131, 59);
}

.datatype-color-rxclaims {
    background-color: rgb(71, 191, 96);
}

.datatype-color-medicalclaims {
    background-color: rgb(81, 151, 213);
}

.datatype-color-lab {
    background-color: rgb(146, 94, 177);
}

.datatype-color-biospecimen {
    background-color: rgb(129, 129, 129);
}

.datatype-color-hospital {
    background-color: rgb(69, 104, 229);
}

.query-blder-feed-name {
    margin: 2px 10px;
    font: normal normal normal 16px/18px Ubuntu Medium;
}

.query-blder-feed-remove {
    margin: 0px 10px 0 0px;
    cursor: pointer;

    &:hover {
        background-color: #B5B5B5;
    }
}

.query-blder-toolbar-run-query-button {
    min-width: 155px;
    height: 100%;
    background: transparent linear-gradient(180deg, #72b456 0%, #578c41 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000033;
    border: 0px transparent;
    font-family: 'Ubuntu Medium';
    font-size: 16px;
    color: #ffffff;
    border-left: white solid 1px;
    box-sizing: border-box;
  
    &:hover {
      background: transparent linear-gradient(180deg, #578c41 0%, #578c41 100%) 0% 0% no-repeat
        padding-box;
      box-shadow: 0px 0px 6px #00000033;
    }
  
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    svg {
        display: inline-block;
        vertical-align: middle;
    }
}

.button-icon {
    display: inline-block;
    position: relative;
    top: 3px;
  
    svg:hover path {
      fill: black;
    }
}
