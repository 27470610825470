@import './QueryBuilder';

.query-blder-criteria-group-container {
    @extend .query-bldr-bordered-container;
    border-width: 2px;
    margin: 0px 22px;

    display: flex;
    align-items: center;

    &:first-of-type {
        margin-top: 30px;
    }
    &:nth-last-of-type(2) {
        // The last criteria will be the second-to-last div element
        margin-bottom: 22px;
    }
    &:not(:nth-last-of-type(2)) + div.logical-operator-line {
        // Display separator if not last criteria
        display: block;
    }

}

.logical-operator-line {
    display: none;
    border-bottom: 2px dashed #D6D6D6;
    text-align: center;
    height: 13px;
    width: 95%;
    margin: 3px auto 14px auto;
    .query-blder-criteria-logical-operator {
        color: #D6D6D6;
        font: normal normal normal 13px/14px Ubuntu Medium;
        background: #fff;
        padding: 0 6px;
    }
}

.query-blder-criteria-group {
    flex: 1;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 5px;
}

.query-blder-criteria-configure {
    margin: 0;
    display: flex;
    align-items: center;
    div.criteria-options-select {
        min-width: 205px;
        padding-right: 5px;
        div.select__menu {
            z-index: 11;
        }
    }
}

.query-blder-criteria-configure-expand-window {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: left;
    font: italic normal normal 14px/15px Ubuntu Medium;
    letter-spacing: -0.26px;
    color: #C4C4C4;
    opacity: 1;
    background: #F4F4F4 0% 0% no-repeat padding-box;
    border: 1px solid #D6D6D6;
    border-radius: 3px;
    opacity: 1;
    padding: 8px 24px;
    width: 545px;
    background-image: url(../../images/Union.svg);
    background-position: 1% 50%;
}

.service-date-configure > *:not(:last-child) {
    margin-right: 5px;
}

.query-blder-criteria-display {
    margin: 1px 10px;
    padding: 5px 10px;
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;
    background-color: #D6D6D6;
}

.query-blder-criteria-name {
    padding-right: 20px;
}

.query-blder-criteria-display-values {
    display: flex;
    align-items: center;
}

.query-blder-criteria-item {
    padding-right: 20px;
}

.query-blder-criteria-remove {
    cursor: pointer;
    &:hover {
        background-color: #B5B5B5;
    }
}
.separator-tuple {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.separator-value {
    display: flex;
    height: 20px;
    background: #939393 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    color: #FFFFFF;
    font-size: 13px;
    padding: 2px;
    margin: 5px;
    justify-content: center;
    align-items: center;
}

.separator-string {
    padding: 2px;
    margin: 5px;
    background-color: #D6D6D6;
    color: #C9C9C9;
    font-size: 13px;
}

.textbox {
    width: 250px;
    height: 170px;
    background: white;
    color: white;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.criteria-arrow-body {
    width: 35px;
    height: 4px;
    background-color: #D6D6D6;
    margin-left: -5px;
}

.date-criteria-arrow-body {
    width: 35px;
    height: 4px;
    background-color: #D6D6D6;
}

.criteria-arrow-head {
    border-left: 8px solid #D6D6D6;
    border-right: 0;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    display: inline-block;
}

.common-criteria-configure-box {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.selected-date-button {
    background-color: lightblue;
    border: 2px solid blue;
    text-align: center;
    line-height: 28px;
    width: 150px;
    height: 28px;
}

.unselected-date-button {
    background-color: #fff;
    border: 2px solid #D6D6D6;
    text-align: center;
    line-height: 28px;
    width: 150px;
    height: 28px;
}

.button-right {
    border-radius: 0px 3px 3px 0px;
}

.button-left {
    border-radius: 3px 0px 0px 3px;
}

.query-blder-spacer {
    min-height: 25px;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    background-color: #D6D6D6;
}
    