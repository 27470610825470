.modal-error-tag {
    border-radius: 0px 4px 4px 0px;
    padding: 1px 5px;
    color: #cb5553;
    max-width: 313px;
    font-style: italic;
    margin-bottom: -26px;
    &.indented {
        margin-left: 37px;
    }
}
